import * as React from 'react';

function SvgCheckmark(props) {
    return (
        <svg
            style={{
                isolation: 'isolate'
            }}
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            {...props}
        >
            <defs>
                <clipPath id="checkmark_svg__a">
                    <path d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
            <g clipPath="url(#checkmark_svg__a)">
                <path
                    d="M5.525 11.096l7.778-7.778a1 1 0 011.415 1.414l-8.486 8.486a1 1 0 01-1.414 0L1.282 9.682a1 1 0 011.415-1.414l2.828 2.828z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}

export default SvgCheckmark;

import * as React from 'react';

function SvgInfo(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 4 12" {...props}>
            <g fill="currentColor" fillRule="nonzero">
                <path d="M1.56 11.764H.794a.182.182 0 00-.034-.026c-.485-.221-.718-.666-.588-1.185.347-1.43.706-2.859 1.059-4.288a4.47 4.47 0 00.104-.601.14.14 0 00-.187-.167c-.08.018-.16.045-.235.08-.174.074-.344.16-.519.227A.295.295 0 010 5.728V5.45a3.16 3.16 0 01.401-.46 49.46 49.46 0 011.604-1.2c.116-.077.24-.14.371-.188a.904.904 0 01.543-.056c.681.137 1.034.683.868 1.355-.336 1.377-.674 2.755-1.014 4.132a5.53 5.53 0 00-.118.681.133.133 0 00.193.15c.128-.038.25-.1.374-.151.15-.066.307-.126.46-.184a.221.221 0 01.25.073.235.235 0 01.026.27c-.039.081-.087.156-.145.223a3.91 3.91 0 01-.408.394c-.581.469-1.177.929-1.845 1.276zM2.55 2.897A1.448 1.448 0 112.554 0a1.448 1.448 0 01-.002 2.897z" />
            </g>
        </svg>
    );
}

export default SvgInfo;

import * as React from 'react';

function SvgPlus(props) {
    return (
        <svg height="1em" viewBox="0 0 10 10" width="1em" {...props}>
            <rect height={1.667} rx={0.833} width={10} y={4.167} />
            <path d="M5.833.833v8.334a.833.833 0 11-1.666 0V.833a.833.833 0 111.666 0z" />
        </svg>
    );
}

export default SvgPlus;

function Locked() {
    return (
        <div className="h-screen flex justify-center items-center bg-dark-90">
            <div className="p-8 rounded bg-white shadow">
                <div className="w-90">
                    <p className="mb-5 text">
                        Der Webcard Editor ist in einem anderen Fenster geöffnet. Den Webcard Editor in mehreren Browser-Fenstern zu verwenden kann zu Datenverlust führen.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Locked;

import clsx from 'clsx';
import { ReactElement } from 'react';
import { useLayer, useHover, Arrow, UseLayerOptions } from 'react-laag';
import { animated, useTransition } from 'react-spring';

type Props = {
    children: ReactElement;
    label: string;
    className?: string;
    isEnabled?: boolean;
} & Omit<UseLayerOptions, 'isOpen'>;

function Tooltip({ className, children, label, isEnabled = true, ...props }: Props) {
    let [isOver, hoverProps] = useHover();

    let { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({ ...props, isOpen: isOver && isEnabled });

    let transition = useTransition(isOver && isEnabled, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    });

    return (
        <>
            <span {...triggerProps} {...hoverProps}>
                {children}
            </span>
            {renderLayer(
                transition.map(
                    ({ item, key, props }) =>
                        item && (
                            <div className={clsx(className, 'z-50')} key={key} {...layerProps}>
                                <animated.div style={props}>
                                    <Arrow {...arrowProps} backgroundColor="#22314D" size={5} roundness={1} />
                                    <div className="bg-dark-100 text-white py-1 px-2 text-xs rounded font-semibold">{label}</div>
                                </animated.div>
                            </div>
                        )
                )
            )}
        </>
    );
}

export default Tooltip;

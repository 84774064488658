import * as React from 'react';

function SvgArrowLeft(props) {
    return (
        <svg height="1em" viewBox="0 0 16 16" width="1em" {...props}>
            <defs>
                <path id="arrow-left_svg__a" d="M0 0h16v16H0z" />
                <mask id="arrow-left_svg__b" fill="#fff">
                    <use fillRule="evenodd" xlinkHref="#arrow-left_svg__a" />
                </mask>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h16v16H0z" />
                <g fill="currentColor">
                    <g mask="url(#arrow-left_svg__b)">
                        <g transform="rotate(45 1.5 9.207)">
                            <rect height={9} rx={1} width={2} />
                            <path d="M9 8a1 1 0 01-1 1H1a1 1 0 010-2h7a1 1 0 011 1z" />
                        </g>
                    </g>
                    <rect height={2} mask="url(#arrow-left_svg__b)" rx={1} width={13} x={2} y={7} />
                </g>
            </g>
        </svg>
    );
}

export default SvgArrowLeft;

import { useCombobox } from 'downshift';
import clsx from 'clsx';
import { useState, InputHTMLAttributes } from 'react';

import IconPlus from './icons/Plus';
import Input from './Input';

import { fetchFromApi } from '../lib/utils';
import { ITag } from '@dogado/webcard-shared';

type ComboboxProps = {
    items?: Array<{
        title: string;
    }>;
    showPlusIcon?: boolean;
    defaultInputValue?: string;
    onSelect: (item: { title: string }) => void;
    selectedItems?: Array<{
        title: string;
    }>;
    resetInputValueOnSelect?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onSelect'>;

function Combobox({
    items = [],
    onSelect,
    placeholder,
    selectedItems = [],
    showPlusIcon = true,
    onChange,
    onBlur,
    onInput,
    defaultInputValue,
    resetInputValueOnSelect = false
}: ComboboxProps) {
    const [inputItems, setInputItems] = useState(items);
    const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, inputValue } = useCombobox({
        items: inputItems,
        defaultInputValue: defaultInputValue,
        itemToString: (item) => (item ? item.title : ''),
        onSelectedItemChange: (changes) => {
            if (false === resetInputValueOnSelect) {
                // Prevent bad setState with setTimeout
                setTimeout(() => {
                    changes.selectedItem && onSelect(changes.selectedItem);
                }, 0);
            }
        },
        onInputValueChange: ({ inputValue }) => {
            let first = inputValue
                ? {
                      title: inputValue
                  }
                : undefined;

            if (inputValue && inputValue.length > 2) {
                fetchFromApi<ITag[]>(`/public/tag?query=${inputValue}`, {
                    method: 'GET'
                }).then((results) => {
                    let data = results.data;

                    if (data) {
                        let filtered: ITag[] = data.filter((tag) => {
                            if (first && tag.title === first.title) {
                                first = tag;

                                return false;
                            }

                            return false === selectedItems.some((t) => t.title === tag.title);
                        });

                        setInputItems(first ? [first, ...filtered] : filtered);
                    } else {
                        setInputItems(first ? [first] : []);
                    }
                });
            } else {
                setInputItems(first ? [first] : []);
            }
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (resetInputValueOnSelect) {
                        setTimeout(() => {
                            onSelect({ title: changes.inputValue || '' });
                        }, 0);

                        return {
                            ...changes,
                            inputValue: '' // don't add the item string as input value at selection.
                        };
                    }

                    return changes;
                default:
                    return changes;
            }
        }
    });

    return (
        <div className="relative">
            <div {...getComboboxProps()}>
                <Input
                    placeholder={placeholder}
                    {...getInputProps({
                        onChange,
                        onBlur,
                        onInput,
                        value: inputValue || ''
                    })}
                />
            </div>
            <ul
                {...getMenuProps()}
                className={clsx(
                    'rounded shadow py-4 px-2 bg-white mt-3 absolute w-full border border-dark-6 z-50',
                    false === (isOpen && inputValue.length > 0) && 'hidden'
                )}
            >
                {inputItems.map((item, index) => (
                    <li
                        className={clsx(
                            'px-2 rounded leading-8 flex flex-row justify-between items-center transition-colors duration-150 cursor-pointer',
                            index === highlightedIndex && 'bg-light'
                        )}
                        key={index}
                        {...getItemProps({
                            item,
                            index
                        })}
                    >
                        {item.title}
                        {showPlusIcon && index === 0 && (
                            <IconPlus
                                className={clsx(
                                    'transition-opacity duration-150 fill-current text-dark-100 w-3 h-3',
                                    index === highlightedIndex ? 'opacity-100' : 'opacity-0'
                                )}
                            />
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Combobox;

import clsx from 'clsx';
import { forwardRef, MouseEvent, ReactNode } from 'react';
import Link from 'next/link';

type Variants = 'primary' | 'secondary' | 'text' | 'outline-inverted' | 'danger' | 'facebook' | 'twitter' | 'disabled';
type Sizes = 'medium' | 'large';

type ButtonProps = {
    id?: string;
    children: ReactNode;
    href?: string;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    variant?: Variants;
    className?: string;
    disabled?: boolean;
    as?: string;
    external?: boolean;
    size?: Sizes;
    onMouseEnter?: <T>(e: MouseEvent<T>) => unknown;
    onMouseLeave?: <T>(e: MouseEvent<T>) => unknown;
};

function Button(
    {
        children,
        type = 'button',
        href,
        onClick,
        variant,
        className,
        disabled = false,
        as,
        external,
        size = 'medium',
        ...props
    }: ButtonProps,
    ref: any
) {
    let variants: Record<Variants, string> = {
        primary: 'bg-primary text-primary-contrast hover:bg-primary-dark font-medium',
        secondary: 'bg-light text-dark-100 hover:bg-gray2-400',
        danger: 'bg-red-600 text-white',
        text: 'hover:bg-light',
        'outline-inverted': 'text-white border border-white hover:bg-white hover:text-dark-100',
        facebook: 'bg-facebook text-white hover:bg-facebook-dark',
        twitter: 'bg-twitter text-white hover:bg-twitter-dark',
        disabled: 'bg-gray-200 text-gray-500'
    };

    let _className = clsx(
        variant && variants[variant],
        {
            'opacity-50 cursor-not-allowed': disabled
        },
        'transition-all duration-150 ease-in focus:shadow-outline outline-none focus:outline-none rounded inline-flex items-center leading-none text-center',
        size === 'medium' && 'px-3 leading-button text-sm',
        size === 'large' && 'px-4 py-5 text-sm',
        className
    );

    if (href) {
        if (external) {
            return (
                <a className={_className} href={href} target="_blank" rel="noopener noreferrer" {...props} ref={ref}>
                    {children}
                </a>
            );
        }

        return (
            <Link as={as} href={href}>
                <a className={_className} {...props} ref={ref}>
                    {children}
                </a>
            </Link>
        );
    }

    return (
        <button className={_className} type={type} onClick={onClick} {...props} ref={ref}>
            {children}
        </button>
    );
}

export default forwardRef(Button);
